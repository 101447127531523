//https://react-data-table-component.netlify.app/?path=/docs/getting-started-intro--page
//npm uninstall react-data-table-component
import React, { Component } from 'react';
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxColor from "global/components/Page/InputHtml/HBoxColor";
//import fuScript from "global/utils/funScript";
class DataTableHarys extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    componentDidMount = () => { this.loadJS(this.data.id) }
    loadJS = (stNomObj) => {
        const stPageLength = this.data.rowsPage || 100
        let stLengthChange = this.data.lengthChange
        if (this.data.lengthChange == null) stLengthChange = false

        //console.log("stLengthChange",stLengthChange,this.data.lengthChange)
        if (document.getElementById(stNomObj)) return
        $(function () {
            $("#" + stNomObj).DataTable({
                "destroy": true,
                /*"infoCallback": function( settings, start, end, max, total, pre ) {
                  var api = this.api();
                  var pageInfo = api.page.info();
                  return 'Página '+ (pageInfo.page+1) +' de '+ pageInfo.pages;
                },*/
                "language": {
                    "lengthMenu": "Registros por página _MENU_",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "No hay registros disponibles",
                    "infoFiltered": "(filtrada de _MAX_ registros)",
                    "loadingRecords": "Cargando...",
                    "processing": "Procesando...",
                    "search": "Buscar:",
                    "zeroRecords": "No se encontraron registros coincidentes",
                    "paginate": {
                        "next": "Siguiente",
                        "previous": "Anterior"
                    },
                },
                "responsive": true,
                "lengthChange": stLengthChange,
                "autoWidth": true,
                "paging": false,   //<=====
                "searching": true,//<=====
                "ordering": true, //<=====
                "info": false,   //<===== Mosrando página 1 de 1
                "pageLength": stPageLength
                //this.data.rowsPage ||


                //         //,"buttons": ["copy", "csv", "excel", "pdf", "print"/*, "colvis"*/]
            }).buttons().container().appendTo('#' + stNomObj + '_wrapper .col-md-6:eq(0)');
        })
    }

    render = () => {
        return (<>
            <div className="XXmodal-dialog modal-dialog-scrollable modal-lg XXcard">
                {!this.data.titulo && (<div className="card-header text-bg-primary d-flex align-items-center" style={{ height: '20px' }}></div>)}
                {this.data.titulo && (
                    <div className="card-header text-bg-primary d-flex align-items-center">
                        <h3 className="tbhar-titulo card-title text-white mb-0" >
                            <b>{this.data.titulo || ""}</b>
                        </h3>
                        <div className="card-actions cursor-pointer ms-auto d-flex button-group">
                            {/* <a href="void(0)" className="link d-flex text-white align-items-center"
                                data-action="collapse"><i className="ti ti-minus fs-6"></i></a>
                            <a href="void(0)"
                                className="mb-0 text-white btn-minimize px-2 cursor-pointer link d-flex align-items-center"
                                data-action="expand"><i className="ti ti-arrows-maximize fs-6"></i></a>
                            <a href="void(0)"
                                className="mb-0 link text-white d-flex align-items-center pe-0 cursor-pointer"
                                data-action="close">
                                <i className="ti ti-x fs-6"></i>
                            </a> */}
                            <HBoxButtonCrud {...this.props} />
                        </div>
                    </div>
                )}
                <div className="modal-body XXcard-body XXcollapse show">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table
                                style={{ width: '100%' }}
                                id={this.data.id}
                                className="table border table-striped table-bordered text-nowrap align-middle dataTable 
                                cell-border
                                display compact
                                order-column
                                row-border
                                stripe
                                hover">
                                {this.fuTableTitulos()}
                                {false && this.fuTableSearch()}
                                <tbody>
                                    {this.data.rows && this.data.rows.map((regRow, index) => {
                                        return (<tr key={'fil_' + index} className="jsgrid-row">
                                            {/*<td><input className="form-check-input m-0 align-middle" type="checkbox" aria-label="Select invoice" /></td>*/}

                                            {this.data.titulos.map((colVal, iCol) => {
                                                if (this.data.titulos[iCol].id.startsWith("cc_"))
                                                    return (<></>)
                                                else
                                                    if (this.data.titulos[iCol].id.startsWith("st_"))
                                                        return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                            className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                            style={this.data.titulos[iCol].style || { width: '70px' }} >
                                                            <div className="form-group">
                                                                <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                                    <button onClick={() => this.data.titulos[iCol].accion(regRow, index)}
                                                                        className={this.data.titulos[iCol].clsArrBoton[regRow[this.data.titulos[iCol].id]] }
                                                                        style={{ borderRadius: '10%', width: '50px', marginLeft: '10px' }}
                                                                    ><i className={this.data.titulos[iCol].clsArrIcon[regRow[this.data.titulos[iCol].id]]}
                                                                        style={{ width: '10px' }}></i>
                                                                        {/*regRow[this.data.titulos[iCol].id]*/}
                                                                        </button>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        )
                                                    else
                                                        if (this.data.titulos[iCol].id.startsWith("ch_"))
                                                            return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                style={this.data.titulos[iCol].style || { width: '70px' }} >
                                                                <div className="form-group">
                                                                    <div className="custom-control custom-switch">
                                                                        <input checked={regRow[this.data.titulos[iCol].id] == "1" ? "checked" : ""}
                                                                            type="checkbox"
                                                                            readOnly
                                                                            className="custom-control-input"
                                                                            id={"ch_" + regRow[this.data.titulos[iCol].id] + "_" + index} />
                                                                        <label className="custom-control-label" htmlFor={"ch_" + regRow[this.data.titulos[iCol].id] + "_" + index}>{regRow[this.data.titulos[iCol].id] == "1" ? "ON" : "OFF"}</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            )
                                                        else
                                                            if (this.data.titulos[iCol].id.startsWith("col_"))
                                                                return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                    className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                    style={this.data.titulos[iCol].style || { width: '170px' }}>
                                                                    <HBoxColor
                                                                        id="txBarra"
                                                                        color={regRow[this.data.titulos[iCol].id]}
                                                                    />
                                                                </td>
                                                                )
                                                            else
                                                                if (this.data.titulos[iCol].id.startsWith("bt_") && this.data.titulos[iCol].accion)
                                                                    return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                        className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                        style={this.data.titulos[iCol].style || { width: '30px' }}>
                                                                        <button onClick={() => this.data.titulos[iCol].accion(regRow, index)}
                                                                            className={this.data.titulos[iCol].clsBoton || "btn btn-success btn-outline-success1"}
                                                                            style={{ borderRadius: '10%', width: '50px', marginLeft: '10px' }}
                                                                        ><i className={this.data.titulos[iCol].clsIcono}
                                                                            style={{ width: '20px' }}></i></button>

                                                                    </td>
                                                                    )
                                                                else
                                                                    if (this.data.titulos[iCol].id.startsWith("upl_") && this.data.titulos[iCol].accion)
                                                                        return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                            className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                            style={this.data.titulos[iCol].style || { width: '70px' }}>
                                                                            <div className="image-upload">
                                                                                <label htmlFor={"file-input_" + index}>
                                                                                    <a className="btn btn-primary">
                                                                                        <i className={this.data.titulos[iCol].clsIcono}></i>
                                                                                    </a>
                                                                                </label>
                                                                                <input id={"file-input_" + index}
                                                                                    type="file"
                                                                                    style={{ display: 'none' }}
                                                                                    onChange={() => this.data.titulos[iCol].accion(regRow, index)} />
                                                                            </div></td>)
                                                                    else
                                                                        if (this.data.titulos[iCol].id.startsWith("i64_"))
                                                                            return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                                className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                                style={this.data.titulos[iCol].style || { width: '70px' }}
                                                                            >
                                                                                <img align="center"
                                                                                    className="img-responsive"
                                                                                    src={regRow[this.data.titulos[iCol].id]}
                                                                                    style={{ width: '100px' }} />
                                                                            </td>)
                                                                        else

                                                                            if (this.data.titulos[iCol].id.startsWith("img_"))
                                                                                return (<td key={this.data.titulos[iCol].id + '_' + index}
                                                                                    className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                                    style={this.data.titulos[iCol].style || { width: '70px' }}
                                                                                >
                                                                                    <img align="center"
                                                                                        className="img-responsive"
                                                                                        src={regRow[this.data.titulos[iCol].id]}
                                                                                        style={{ width: '100px' }} />
                                                                                </td>)
                                                                            else
                                                                                return <td key={this.data.titulos[iCol].id + '_' + index}
                                                                                    className={"jsgrid-cell " + this.data.titulos[iCol].clsName}
                                                                                    style={this.data.titulos[iCol].style}
                                                                                >
                                                                                    {regRow[this.data.titulos[iCol].id]}
                                                                                </td>

                                            })}
                                        </tr>)
                                    }
                                    )}


                                </tbody>
                                {false && (<tfoot>
                                    <tr>
                                        {/*<th width="1%" data-orderable="false"></th>*/}
                                        {this.data.titulos.map((colVal, iCol) => {
                                            if (this.data.titulos[iCol].id.startsWith("bt_")
                                                || this.data.titulos[iCol].id.startsWith("upl_"))
                                                return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                                    className="text-nowrap"
                                                    style={{ width: '70px' }}
                                                >
                                                    {this.data.titulos[iCol].label}
                                                </th>)
                                            else
                                                return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                                    className="text-nowrap"
                                                    style={this.data.titulos[iCol].style}
                                                >
                                                    {this.data.titulos[iCol].label}
                                                </th>)
                                        })}
                                    </tr>
                                </tfoot>)}
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </>)
    }
    fuTableSearch = () => {
        return (<thead>
            <tr>
                {this.data.titulos.map((colVal, iCol) => {
                    if (!this.data.titulos[iCol].id.startsWith("cc_"))
                        if (this.data.titulos[iCol].id.startsWith("bt_")
                            || this.data.titulos[iCol].id.startsWith("upl_"))
                            return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                className="text-nowrap"
                                style={{ width: '70px' }}
                            >
                                ...
                            </th>)
                        else
                            return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                className="text-nowrap"
                                style={this.data.titulos[iCol].style}
                            >
                                <input />
                            </th>)
                })}
            </tr>
        </thead>)
    }
    fuTableTitulos = () => {
        //console.log("this.data.titulos", this.data.titulos)
        return (<thead>
            <tr >
                {/*<th width="1%" data-orderable="false"></th>*/}
                {this.data.titulos.map((colVal, iCol) => {
                    if (!this.data.titulos[iCol].id.startsWith("cc_"))
                        if (this.data.titulos[iCol].align != null) {
                            if (this.data.titulos[iCol].style == null) this.data.titulos[iCol].style = {}
                            if (this.data.titulos[iCol].style["textAlign"] == null)
                                if (this.data.titulos[iCol].align == "right") this.data.titulos[iCol].style["textAlign"] = "right"
                                else if (this.data.titulos[iCol].align == "left") this.data.titulos[iCol].style["textAlign"] = "left"
                                else if (this.data.titulos[iCol].align == "center") this.data.titulos[iCol].style["textAlign"] = "center"
                        }
                    if (!this.data.titulos[iCol].id.startsWith("cc_"))
                        if (this.data.titulos[iCol].id.startsWith("bt_")
                            || this.data.titulos[iCol].id.startsWith("upl_"))
                            return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                className={"text-nowrap " + this.data.titulos[iCol].clsNameTit + " " + + this.data.titulos[iCol].clsName}
                                style={{ width: '70px' }}
                            ><b>{this.data.titulos[iCol].label}</b>
                            </th>)
                        else
                            if (this.data.titulos[iCol].id.startsWith("st_"))
                                return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                    className={"text-nowrap " + this.data.titulos[iCol].clsNameTit + " " + + this.data.titulos[iCol].clsName}
                                    style={{ width: '70px' }}
                                ><label>{this.data.titulos[iCol].label}</label>
                                </th>)
                            else
                                return (<th key={this.data.titulos[iCol].id + '_' + iCol}
                                    className={"text-nowrap " + this.data.titulos[iCol].clsNameTit + " " + + this.data.titulos[iCol].clsName}
                                    style={this.data.titulos[iCol].style}
                                >
                                    <label>{this.data.titulos[iCol].label}</label>
                                </th>)
                })}
            </tr>
        </thead>)
    }

}

export default DataTableHarys;